import "../style.scss";
import { formatMoney } from "@src/app/utils/money";
import { getNonIPAmount } from "@src/app/utils/shiftHelper";
import { CbhFeatureFlag, FeatureFlagVariants } from "@src/appV2/FeatureFlags";
import { Shift } from "@src/lib/interface";

import { formatRate } from "../../hcpShifts/helper";

interface ShiftPayRateProps {
  shift: Shift;
  isUrgentShift?: boolean;
  ldFlags: FeatureFlagVariants;
}

export const ShiftPayRate = ({ shift, isUrgentShift, ldFlags }: ShiftPayRateProps) => {
  if (!(ldFlags[CbhFeatureFlag.URGENT_SHIFTS_HOURLY_PAY_RATE] ?? true)) {
    const originalAmount = getNonIPAmount(shift, ldFlags);
    if (isUrgentShift) {
      return <strong>${formatRate(originalAmount)} total earnings</strong>;
    }
  }

  const amount = shift.offer?.pay?.value ?? shift.finalPay ?? 0;
  const currency = shift.offer?.pay ? shift.offer.pay.currency : "USD";

  return (
    <>
      <strong>{formatMoney({ amount, currency })}</strong> per hour
    </>
  );
};
