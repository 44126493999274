import pluralize from "pluralize";
/**
 * Get formatted shift hours plus lunch break hours
 * (Lunch break is hard coded to 30 mins.)
 */
export function formatShiftHours(totalHours: number, withLunchBreak = true): string {
  const LUNCH_BREAK_TIME = 0.5;
  const hoursWithoutLunchBreak = Math.max(0, totalHours + LUNCH_BREAK_TIME);
  const trimmedHour = parseFloat(
    withLunchBreak ? hoursWithoutLunchBreak.toFixed(2) : totalHours.toFixed(2)
  );

  return pluralize("hour", trimmedHour, true);
}
